@charset "utf-8";

$footer-background-color: #00CED0;

@import "minimal-mistakes/skins/mint"; // skin
@import "minimal-mistakes"; // main partials


.page {
    @media (min-width: 80em) {
      width: 100%;
      padding-right: 0;
    }
  }
  